import { querySections, getSection } from '@wix/ambassador-restaurants-menus-v1-section/http';
import type { Section } from 'root/types';
import type { HttpClientFactory } from './utils/types';

export const SectionsClient: HttpClientFactory<Section> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getSection({ sectionId: id }))
      .then((res) => {
        const {
          data: { section = {} },
        } = res;

        return { data: section as Section };
      })
      .catch((e) => {
        throw new Error(`Error when calling getSection with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    const filter = ids ? { id: { $in: ids } } : undefined;
    return httpClient
      .request(querySections({ query: { cursorPaging: paging, filter } }))
      .then((res) => {
        const {
          data: { sections = [], pagingMetadata },
        } = res;

        return { data: sections as Section[], pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getSections- ${e}`);
      });
  },
});
